import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 909.000000 728.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,728.000000) scale(0.100000,-0.100000)">
					<path d="M5092 5408 l3 -143 145 0 145 0 0 140 0 140 -148 3 -148 3 3 -143z" />
					<path d="M3367 5404 c-4 -4 -7 -436 -7 -960 0 -788 2 -953 13 -957 8 -3 110
-4 228 -4 l214 2 3 610 c1 335 6 613 11 618 4 4 27 -13 51 -40 175 -199 317
-512 370 -818 7 -38 16 -92 21 -118 5 -26 12 -93 15 -148 6 -90 9 -100 28
-105 12 -2 111 -3 221 -2 l200 3 3 886 2 886 -67 36 c-111 58 -355 129 -384
111 -5 -3 -9 -127 -9 -282 l0 -277 -57 65 c-173 199 -337 326 -538 417 -99 45
-301 94 -318 77z"/>
					<path d="M5490 5277 c0 -74 3 -87 16 -87 12 0 14 4 6 13 -5 6 -10 43 -10 81
-1 38 -4 71 -7 74 -3 3 -5 -34 -5 -81z"/>
					<path d="M5528 5353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M5596 5352 c6 -4 11 -38 10 -84 -1 -85 10 -107 16 -30 5 81 -1 122
-20 122 -11 0 -13 -3 -6 -8z"/>
					<path d="M5638 5275 c-2 -47 1 -84 5 -82 10 3 16 167 6 167 -4 0 -9 -38 -11
-85z"/>
					<path d="M5520 5265 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
					<path d="M4860 5127 c0 -18 -2 -110 -4 -204 -3 -95 -1 -173 4 -173 5 0 10 6
12 12 3 9 53 13 189 13 101 0 174 1 161 3 -12 2 -20 7 -17 11 2 5 -6 7 -18 5
-62 -9 -299 -11 -286 -3 12 7 11 9 -3 9 -9 0 -19 -6 -21 -12 -3 -7 -6 69 -6
169 -1 133 2 182 11 185 7 3 6 7 -5 11 -14 5 -17 0 -17 -26z"/>
					<path d="M5250 5099 c0 -34 2 -60 4 -58 8 9 12 110 4 115 -4 3 -8 -23 -8 -57z" />
					<path d="M5175 5143 c11 -3 23 -10 27 -17 5 -7 8 -5 8 7 0 12 -7 17 -27 16
-22 -1 -23 -2 -8 -6z"/>
					<path d="M5227 5135 c-4 -8 -2 -17 3 -20 6 -4 10 3 10 14 0 25 -6 27 -13 6z" />
					<path d="M5008 5123 c23 -2 59 -2 80 0 20 2 1 4 -43 4 -44 0 -61 -2 -37 -4z" />
					<path d="M5370 4960 l0 -151 143 3 142 3 0 145 0 145 -142 3 -143 3 0 -151z" />
					<path d="M4926 5091 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"/>
					<path d="M5224 5046 c-16 -17 -23 -187 -8 -171 8 8 27 189 20 185 -1 -1 -6 -7
-12 -14z"/>
					<path d="M4883 4960 c0 -47 2 -66 4 -42 2 23 2 61 0 85 -2 23 -4 4 -4 -43z" />
					<path d="M5202 4830 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M4963 4753 c48 -2 126 -2 175 0 48 1 8 3 -88 3 -96 0 -136 -2 -87 -3z" />
					<path d="M4889 4558 c174 -256 287 -608 317 -985 5 -69 9 -82 26 -86 10 -3
112 -5 226 -4 l207 2 -1 105 c-1 279 -110 650 -280 960 l-41 75 -252 3 -252 2
50 -72z"/>
					<path d="M2528 3013 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
					<path d="M2575 3014 c55 -22 124 -39 115 -29 -6 7 -30 18 -55 24 -42 10 -81
14 -60 5z"/>
					<path d="M5341 3010 c-55 -24 -91 -43 -88 -46 2 -3 29 7 59 20 31 14 64 23 74
21 58 -12 127 -14 117 -4 -15 15 -133 21 -162 9z"/>
					<path d="M1548 3003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M1608 3003 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z" />
					<path d="M1707 2990 c3 -11 9 -23 14 -26 5 -3 9 -14 9 -25 0 -11 -4 -18 -10
-14 -5 3 -7 12 -3 20 3 8 0 15 -6 15 -15 0 -13 -41 3 -63 13 -17 15 -16 21 12
4 17 1 46 -6 66 -14 37 -31 49 -22 15z"/>
					<path d="M2005 3005 c23 -8 174 -11 169 -3 -3 4 -44 8 -92 7 -48 0 -82 -2 -77
-4z"/>
					<path d="M2956 3001 c-4 -5 -2 -12 4 -16 5 -3 10 -1 10 4 0 7 46 11 133 11
114 0 136 -3 164 -20 30 -18 63 -18 63 0 0 15 -65 23 -217 27 -92 2 -154 0
-157 -6z"/>
					<path d="M3515 2997 c-3 -7 -4 -127 -3 -267 l3 -255 60 0 61 0 3 267 3 268
-61 0 c-41 0 -63 -4 -66 -13z m115 -199 c0 -112 -3 -227 -6 -255 -6 -50 -8
-53 -35 -53 -34 0 -32 -21 -33 273 -1 171 1 196 14 191 11 -4 12 -2 3 7 -31
31 -35 -1 -32 -239 3 -182 1 -236 -8 -239 -10 -4 -13 52 -13 256 l0 261 55 0
55 0 0 -202z"/>
					<path d="M3613 2600 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
					<path d="M3778 3003 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
					<path d="M3882 2813 c2 -182 1 -198 -15 -195 -24 4 -21 -5 11 -33 18 -17 39
-24 66 -25 46 0 43 11 -3 12 -18 1 -34 5 -37 10 -3 5 -5 103 -4 218 0 140 -3
210 -10 210 -7 0 -9 -66 -8 -197z"/>
					<path d="M4177 2997 c-3 -7 -6 -120 -6 -251 -2 -220 -1 -237 15 -234 17 3 28
48 12 48 -4 0 -8 99 -8 220 l0 221 155 -3 c85 -2 155 0 155 5 0 4 -72 7 -159
7 -122 0 -160 -3 -164 -13z"/>
					<path d="M4622 2938 l1 -73 6 65 6 65 115 6 115 5 -122 2 -123 2 2 -72z" />
					<path d="M5780 2991 c0 -11 5 -23 11 -27 7 -4 9 0 5 14 -5 16 -2 21 16 25 20
4 20 4 -4 6 -22 1 -28 -4 -28 -18z"/>
					<path d="M5860 3003 c49 -8 56 -16 20 -23 l-35 -7 38 -2 c31 -1 37 2 37 19 0
17 -6 20 -42 19 -26 -1 -33 -3 -18 -6z"/>
					<path d="M6095 3000 c3 -5 1 -10 -6 -10 -7 0 -10 -3 -6 -6 3 -3 16 1 29 10
l22 16 -22 0 c-13 0 -20 -4 -17 -10z"/>
					<path d="M6141 3006 c10 -10 99 -15 99 -6 0 6 -23 10 -52 10 -28 0 -49 -2 -47
-4z"/>
					<path d="M6401 3006 c2 -3 32 -6 67 -7 34 -1 62 -6 62 -11 0 -4 5 -8 10 -8 6
0 10 7 10 15 0 12 -15 15 -77 15 -42 0 -74 -2 -72 -4z"/>
					<path d="M6660 2985 c0 -14 4 -25 9 -25 5 0 8 6 7 13 0 6 4 12 9 12 6 0 9 -3
8 -7 -2 -5 2 -8 7 -8 6 0 10 7 10 15 0 12 25 15 143 18 l142 3 -167 2 -168 2
0 -25z"/>
					<path d="M7112 2738 l3 -273 2 248 c2 170 7 247 14 247 6 0 9 -6 6 -13 -3 -8
-1 -20 4 -28 6 -10 9 -5 9 17 0 19 -6 34 -15 38 -8 3 -15 10 -15 16 0 18 253
11 303 -8 66 -25 91 -52 47 -52 -18 0 -41 -27 -40 -47 1 -4 7 3 14 16 8 15 16
20 20 12 10 -16 26 -14 26 4 0 9 6 12 16 8 37 -14 -12 38 -56 60 -39 19 -64
22 -192 25 l-148 3 2 -273z"/>
					<path d="M2452 2993 c-6 -2 -10 -9 -7 -14 4 -5 0 -6 -8 -3 -8 3 -18 1 -22 -5
-4 -7 5 -11 24 -11 24 0 31 4 31 20 0 11 -1 20 -2 19 -2 0 -9 -3 -16 -6z"/>
					<path d="M4890 2978 c0 -16 3 -19 9 -10 13 21 60 5 54 -18 -3 -13 0 -18 14
-17 14 2 17 -4 15 -23 -2 -20 0 -22 8 -10 9 13 11 13 21 0 8 -12 9 -9 3 10 -6
22 -17 33 -73 77 -26 20 -51 16 -51 -9z"/>
					<path d="M1588 2980 c7 -9 12 -48 13 -104 1 -89 2 -90 10 -36 5 30 6 70 3 88
-4 29 -3 32 21 32 16 0 25 6 25 15 0 18 -37 20 -43 3 -3 -10 -8 -10 -23 2 -15
11 -16 11 -6 0z"/>
					<path d="M1997 2983 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
					<path d="M4673 2983 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
					<path d="M5345 2980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M5534 2975 c11 -8 16 -15 11 -15 -5 0 -4 -5 3 -12 9 -9 15 -9 23 -1
8 8 4 15 -12 27 -29 20 -51 21 -25 1z"/>
					<path d="M6980 2958 l0 -38 -54 0 c-34 0 -56 5 -59 13 -4 9 -8 9 -15 -2 -9
-13 -13 -13 -28 0 -11 9 -15 9 -10 2 4 -7 2 -13 -3 -13 -6 0 -11 7 -11 17 0
15 -2 15 -19 -1 -28 -25 -32 -52 -10 -72 21 -19 40 -11 21 8 -31 31 -14 38 93
38 102 0 105 1 111 24 3 14 1 33 -5 43 -9 15 -10 12 -11 -19z"/>
					<path d="M1564 2810 c0 -96 2 -136 3 -87 2 48 2 126 0 175 -1 48 -3 8 -3 -88z" />
					<path d="M2050 2970 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z" />
					<path d="M2118 2973 c27 -3 40 -8 36 -15 -4 -7 -3 -8 4 -4 7 4 12 -9 15 -36
l4 -43 1 46 c2 53 -6 60 -66 57 -36 -1 -36 -2 6 -5z"/>
					<path d="M2710 2973 c0 -5 16 -23 35 -42 36 -33 49 -30 18 5 -27 29 -53 47
-53 37z"/>
					<path d="M3200 2964 c0 -16 6 -16 35 -4 11 5 16 9 10 10 -5 1 -18 3 -27 5 -10
2 -18 -3 -18 -11z"/>
					<path d="M4800 2970 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
					<path d="M5450 2970 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
					<path d="M6210 2970 l-25 -8 28 -1 c15 -1 27 4 27 9 0 6 -1 10 -2 9 -2 -1 -14
-5 -28 -9z"/>
					<path d="M7280 2970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
					<path d="M7360 2967 c0 -9 12 -14 31 -14 31 0 31 0 7 13 -30 17 -38 17 -38 1z" />
					<path d="M2525 2960 c28 -12 55 -12 55 0 0 6 -17 10 -37 9 -31 0 -34 -2 -18
-9z"/>
					<path d="M2963 2885 c-1 -59 2 -78 6 -55 4 19 4 60 0 90 -5 48 -6 44 -6 -35z" />
					<path d="M3265 2960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M4290 2961 c0 -16 24 -18 70 -5 l45 12 -57 1 c-32 1 -58 -3 -58 -8z" />
					<path d="M4443 2963 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
					<path d="M6077 2957 c-4 -10 -7 -26 -7 -35 1 -15 2 -15 11 1 6 10 9 25 7 35
-3 16 -4 15 -11 -1z"/>
					<path d="M6506 2964 c8 -3 10 -21 8 -47 l-5 -42 15 40 c15 39 9 56 -19 54 -5
0 -5 -3 1 -5z"/>
					<path d="M6903 2963 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
					<path d="M7200 2960 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
					<path d="M1584 2800 c0 -91 2 -128 3 -82 2 45 2 119 0 165 -1 45 -3 8 -3 -83z" />
					<path d="M1681 2944 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M2103 2880 c0 -47 2 -66 4 -42 2 23 2 61 0 85 -2 23 -4 4 -4 -43z" />
					<path d="M2660 2946 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
					<path d="M3336 2929 c-11 -55 -7 -89 9 -89 8 0 15 -5 15 -11 0 -5 5 -7 10 -4
14 9 12 25 -2 23 -8 -2 -14 11 -16 35 -3 40 12 51 23 16 4 -11 9 -18 12 -16 6
7 -27 77 -37 77 -4 0 -10 -14 -14 -31z"/>
					<path d="M3843 2845 c0 -66 2 -133 6 -150 7 -30 7 -30 15 -6 5 14 4 26 -3 30
-6 4 -12 55 -14 126 l-3 120 -1 -120z"/>
					<path d="M4222 2758 c-1 -115 1 -208 6 -208 4 0 6 62 4 137 -4 255 -8 279 -10
71z"/>
					<path d="M4642 2900 c0 -46 2 -58 8 -39 4 14 4 43 0 65 -7 35 -8 32 -8 -26z" />
					<path d="M4727 2953 c-13 -13 -7 -21 13 -16 11 3 20 9 20 14 0 10 -24 12 -33
2z"/>
					<path d="M5409 2946 c10 -12 7 -15 -21 -19 -30 -3 -30 -4 8 -5 45 -2 79 20 48
32 -8 3 -23 6 -31 6 -12 0 -13 -3 -4 -14z"/>
					<path d="M6394 2926 c-3 -21 -4 -39 -1 -42 2 -3 8 11 11 32 4 20 5 38 2 41 -2
3 -8 -11 -12 -31z"/>
					<path d="M6447 2953 c-4 -3 -7 -17 -7 -30 0 -12 -4 -23 -10 -23 -5 0 -10 -4
-10 -10 0 -5 7 -10 15 -10 8 0 17 14 21 35 6 36 3 51 -9 38z"/>
					<path d="M6696 2848 c-3 -62 -8 -126 -11 -143 -4 -28 -4 -28 4 -5 5 14 10 43
12 65 l2 40 7 -45 c4 -25 5 9 2 75 -3 66 -6 121 -7 123 -1 1 -5 -48 -9 -110z"/>
					<path d="M6740 2951 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
					<path d="M2488 2943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M4241 2923 c0 -25 1 -26 9 -8 12 27 12 35 0 35 -5 0 -10 -12 -9 -27z" />
					<path d="M5212 2931 c-37 -32 -72 -119 -71 -181 0 -30 3 -63 7 -72 4 -10 7 11
7 47 0 65 9 90 25 65 5 -8 12 -11 16 -7 4 4 -3 15 -14 26 -20 17 -21 21 -8 45
8 15 16 24 18 19 3 -4 3 -3 2 4 -2 6 13 26 32 42 37 33 25 43 -14 12z"/>
					<path d="M5280 2940 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M5320 2940 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
					<path d="M1969 2908 c-23 -60 -27 -78 -18 -78 5 0 9 7 9 15 0 8 5 15 10 15 6
0 10 6 8 13 -1 6 2 22 6 35 12 37 0 37 -15 0z"/>
					<path d="M2352 2920 c-18 -29 -15 -36 8 -15 20 18 26 35 12 35 -4 0 -13 -9
-20 -20z"/>
					<path d="M3245 2930 c3 -5 12 -10 20 -10 7 0 17 -10 20 -21 6 -17 9 -19 16 -8
5 9 1 20 -11 31 -20 19 -56 25 -45 8z"/>
					<path d="M5605 2920 c7 -23 2 -25 -24 -8 -11 6 -26 8 -37 3 -11 -4 -26 -8 -34
-7 -10 1 -11 0 -2 -4 6 -3 12 -10 12 -15 0 -6 -8 -6 -19 1 -35 18 -47 10 -16
-10 38 -25 45 -25 45 0 0 15 7 20 26 20 23 0 25 -3 19 -31 -6 -26 -4 -30 11
-27 9 1 19 9 22 17 3 8 0 11 -7 7 -15 -9 -14 7 1 22 19 19 26 15 38 -23 14
-43 32 -57 20 -15 -8 29 -46 90 -56 90 -3 0 -2 -9 1 -20z"/>
					<path d="M2391 2914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M2498 2923 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M3046 2915 c4 -8 12 -15 20 -15 19 0 18 16 -2 24 -22 9 -24 8 -18 -9z" />
					<path d="M4288 2901 c-5 -14 -6 -31 -3 -39 3 -8 1 -20 -5 -27 -8 -10 -4 -18
14 -33 14 -10 22 -23 18 -27 -4 -4 3 -2 16 4 13 7 35 12 50 11 15 -1 36 -1 47
1 11 1 -11 5 -50 8 l-70 6 -3 53 -3 52 100 0 c56 0 101 3 101 8 0 4 -46 7
-101 7 -98 0 -102 -1 -111 -24z"/>
					<path d="M4753 2923 c-16 -3 -23 -11 -23 -26 1 -20 2 -20 11 -4 7 11 22 17 46
17 20 0 33 3 29 7 -8 9 -34 11 -63 6z"/>
					<path d="M4855 2921 c-3 -5 6 -21 20 -35 29 -29 31 -46 10 -86 -16 -30 -19
-32 -67 -34 -28 -2 -51 -15 -27 -17 8 0 6 -4 -6 -9 l-20 -9 20 -1 c17 -2 16
-3 -5 -10 -24 -8 -24 -8 -2 -9 12 -1 22 -5 22 -11 0 -5 10 -10 23 -9 l22 0
-23 9 c-19 8 -21 12 -10 29 9 15 16 17 25 9 7 -5 10 -14 7 -19 -3 -5 4 -9 15
-9 18 0 25 -10 23 -31 -1 -4 13 -3 31 0 42 9 43 36 2 37 -65 1 -82 26 -40 57
23 17 25 17 35 -3 6 -11 20 -20 30 -20 30 0 24 17 -9 28 -24 8 -28 14 -24 36
7 32 -13 82 -35 91 -14 5 -14 7 -2 15 9 6 10 10 3 10 -6 0 -14 -4 -18 -9z m85
-215 c0 -2 -9 -6 -20 -9 -11 -3 -20 -1 -20 4 0 5 9 9 20 9 11 0 20 -2 20 -4z"/>
					<path d="M5802 2910 c0 -14 3 -36 7 -50 7 -24 8 -24 15 10 l9 35 7 -30 c7 -27
8 -26 7 13 -1 48 -13 55 -24 15 l-7 -28 -7 30 c-6 26 -7 27 -7 5z"/>
					<path d="M5890 2920 c-1 -8 -3 -28 -5 -45 -2 -16 -1 -23 2 -15 3 8 10 18 15
21 5 3 4 16 -1 30 -6 14 -10 18 -11 9z"/>
					<path d="M7275 2920 c-3 -5 -15 -10 -26 -10 -10 0 -19 -3 -19 -8 0 -4 33 -6
74 -4 73 4 75 4 89 -24 15 -32 6 -94 -15 -94 -6 0 -9 -2 -6 -5 13 -13 37 9 42
40 10 52 -14 91 -61 99 -21 4 -45 9 -55 12 -9 3 -19 0 -23 -6z"/>
					<path d="M7380 2926 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z" />
					<path d="M2585 2910 c-18 -8 -18 -9 6 -9 14 -1 30 -6 37 -13 9 -9 12 -9 12 0
0 7 -5 12 -11 12 -5 0 -7 5 -4 10 7 12 -12 12 -40 0z"/>
					<path d="M2690 2895 c0 -22 -3 -25 -21 -20 l-22 7 21 -23 c12 -12 25 -38 28
-57 6 -28 8 -32 16 -18 7 12 5 25 -7 43 -12 18 -14 28 -6 36 7 7 12 5 17 -6 5
-15 44 -25 44 -12 0 11 -34 44 -42 39 -4 -3 -8 2 -8 10 0 8 -4 18 -10 21 -6 4
-10 -5 -10 -20z"/>
					<path d="M3118 2913 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z" />
					<path d="M3228 2903 c12 -8 22 -23 22 -32 1 -14 3 -13 15 3 13 17 12 20 -11
33 -36 18 -54 16 -26 -4z"/>
					<path d="M4961 2904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M5245 2910 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
					<path d="M2501 2903 c-39 -16 -81 -74 -81 -110 1 -11 6 -7 14 13 19 44 56 79
96 91 29 9 31 11 10 11 -14 0 -31 -2 -39 -5z"/>
					<path d="M5393 2903 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
					<path d="M1680 2879 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
					<path d="M1755 2883 c3 -12 1 -24 -5 -28 -12 -8 -13 -25 -1 -25 14 0 21 42 10
59 -8 13 -9 12 -4 -6z"/>
					<path d="M2790 2899 c0 -4 7 -26 15 -49 18 -50 18 -53 5 -45 -5 3 -10 15 -10
26 0 19 -11 25 -24 12 -3 -4 8 -21 25 -39 30 -31 32 -36 26 -91 -5 -46 -4 -53
4 -33 15 35 2 145 -21 189 -10 20 -19 33 -20 30z"/>
					<path d="M6060 2892 c0 -7 7 -15 15 -18 8 -4 15 -12 15 -19 0 -8 5 -17 10 -20
6 -3 10 3 10 15 0 11 -8 24 -17 27 -10 4 -21 11 -25 17 -5 7 -8 7 -8 -2z"/>
					<path d="M6262 2870 c0 -19 6 -51 14 -70 l14 -35 -6 35 c-3 19 -10 51 -14 70
l-8 35 0 -35z"/>
					<path d="M3077 2866 c-4 -15 -6 -31 -2 -34 10 -10 16 6 13 35 l-3 28 -8 -29z" />
					<path d="M5230 2880 c-26 -16 -4 -21 26 -5 23 12 25 15 9 15 -11 0 -27 -4 -35
-10z"/>
					<path d="M2040 2864 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z" />
					<path d="M2312 2838 c-19 -75 -24 -118 -12 -118 5 0 10 20 10 44 0 23 5 57 11
75 6 18 9 34 7 37 -3 2 -10 -15 -16 -38z"/>
					<path d="M3000 2859 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
					<path d="M4245 2870 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M5022 2848 c-1 -20 -5 -45 -8 -55 -5 -14 -4 -15 5 -7 7 7 11 29 8 55
-3 42 -4 42 -5 7z"/>
					<path d="M5280 2834 c-11 -25 -19 -62 -18 -82 l1 -37 4 35 c5 40 27 92 49 115
14 13 14 15 -1 15 -9 0 -24 -19 -35 -46z"/>
					<path d="M7230 2841 c0 -29 -5 -41 -17 -44 -14 -4 -12 -5 5 -6 20 -1 22 4 22
44 0 25 -2 45 -5 45 -3 0 -5 -18 -5 -39z"/>
					<path d="M6150 2849 c0 -11 4 -18 10 -14 5 3 7 12 3 20 -7 21 -13 19 -13 -6z" />
					<path d="M2376 2844 c-17 -12 -17 -14 -4 -14 9 0 20 5 23 11 11 17 2 18 -19 3z" />
					<path d="M4966 2838 c-9 -35 -7 -58 5 -58 6 0 8 9 4 20 -7 22 10 28 19 8 3 -7
4 0 3 16 -3 36 -22 45 -31 14z"/>
					<path d="M6380 2834 c-12 -31 -12 -34 -1 -34 5 0 11 14 13 30 6 37 1 38 -12 4z" />
					<path d="M6496 2838 c-3 -13 -5 -34 -5 -48 1 -15 5 -7 10 19 9 47 6 68 -5 29z" />
					<path d="M7517 2854 c-3 -4 -4 -18 -1 -33 9 -44 -86 -133 -107 -99 -4 7 -11 9
-16 5 -4 -5 -2 -12 5 -16 7 -4 10 -15 7 -23 -4 -9 -2 -19 4 -23 7 -3 11 2 11
14 0 13 8 23 24 27 44 11 96 88 96 141 0 14 -12 17 -23 7z"/>
					<path d="M1773 2817 c0 -20 5 -34 9 -31 11 7 10 35 -1 53 -6 10 -9 3 -8 -22z" />
					<path d="M3249 2828 c-17 -32 -27 -37 -79 -39 -41 -2 -42 -3 -10 -6 19 -3 38
-10 42 -16 5 -8 13 -5 28 8 20 18 22 18 49 2 28 -16 30 -16 52 3 l24 20 -28
-10 c-34 -12 -49 -6 -40 16 5 14 3 15 -16 4 -21 -11 -22 -10 -11 10 6 12 9 24
7 27 -3 2 -11 -6 -18 -19z"/>
					<path d="M5537 2818 c8 -46 13 -48 13 -5 0 21 -4 37 -10 37 -5 0 -7 -14 -3
-32z"/>
					<path d="M6051 2843 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
					<path d="M7480 2834 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
					<path d="M4252 2820 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M4720 2821 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z" />
					<path d="M5220 2830 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
					<path d="M5610 2825 c0 -9 -4 -14 -9 -11 -14 9 -21 -21 -12 -46 8 -22 9 -21
15 10 5 19 14 32 23 32 17 0 13 22 -5 28 -7 2 -12 -4 -12 -13z"/>
					<path d="M7144 2740 c0 -58 1 -81 3 -52 2 28 2 76 0 105 -2 28 -3 5 -3 -53z" />
					<path d="M1981 2814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M5856 2821 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
					<path d="M5895 2821 c-5 -10 -4 -16 3 -19 18 -6 34 -78 28 -130 -4 -36 -3 -40
3 -16 4 17 11 35 14 41 3 5 -1 28 -9 52 -8 23 -11 47 -8 53 4 6 2 8 -4 4 -6
-3 -12 2 -14 11 -3 15 -5 16 -13 4z"/>
					<path d="M5945 2790 c2 -25 9 -63 14 -85 8 -36 9 -37 9 -10 1 17 -5 55 -13 85
-15 55 -15 55 -10 10z"/>
					<path d="M6786 2817 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
					<path d="M1678 2806 c-19 -8 -24 -16 -18 -25 4 -8 6 -20 3 -27 -2 -6 1 -15 7
-19 8 -5 11 1 10 16 -4 41 -1 49 20 49 11 0 20 5 20 10 0 12 -8 12 -42 -4z"/>
					<path d="M1916 2772 c-10 -29 -15 -56 -12 -59 3 -4 6 1 6 10 0 10 4 17 8 17
15 0 30 44 22 65 -5 16 -10 9 -24 -33z"/>
					<path d="M2745 2810 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M7430 2807 c0 -10 8 -17 18 -17 9 0 13 4 7 8 -5 4 -13 11 -17 16 -5
5 -8 2 -8 -7z"/>
					<path d="M2326 2802 c6 -4 10 -15 8 -25 -1 -9 2 -15 7 -11 16 10 10 44 -8 44
-11 0 -14 -3 -7 -8z"/>
					<path d="M6133 2775 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
					<path d="M6736 2795 c4 -8 10 -15 15 -15 5 0 9 -7 9 -15 0 -8 5 -15 10 -15 14
0 12 12 -6 38 -17 25 -36 30 -28 7z"/>
					<path d="M1751 2785 c3 -44 -2 -65 -12 -65 -6 0 -9 7 -5 15 3 9 -3 24 -14 35
-22 22 -25 19 -11 -8 6 -9 13 -30 16 -47 6 -27 8 -28 17 -11 10 18 12 18 29
-5 10 -13 19 -19 19 -13 0 6 -7 17 -15 24 -9 7 -13 21 -10 30 3 9 1 28 -5 41
-8 20 -10 21 -9 4z"/>
					<path d="M2000 2791 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
					<path d="M2060 2793 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
					<path d="M2990 2786 c0 -7 7 -19 15 -26 12 -10 16 -9 21 3 3 9 3 21 -1 27 -9
15 -35 12 -35 -4z"/>
					<path d="M3040 2790 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
					<path d="M4466 2773 c10 -40 14 -41 14 -5 0 18 -5 32 -10 32 -6 0 -8 -11 -4
-27z"/>
					<path d="M5213 2760 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
					<path d="M5231 2758 c-1 -27 3 -48 7 -48 4 0 6 17 4 38 -5 58 -10 62 -11 10z" />
					<path d="M5631 2784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M5671 2748 c-1 -31 -5 -58 -9 -60 -5 -1 -12 -21 -15 -43 -8 -52 7
-29 28 43 11 37 13 61 6 85 -8 30 -9 28 -10 -25z"/>
					<path d="M6063 2723 c-2 -56 2 -83 16 -109 14 -26 20 -31 25 -18 9 22 8 24
-10 24 -11 0 -13 6 -9 19 3 11 1 22 -6 26 -7 5 -9 22 -5 49 4 23 3 52 -1 64
-5 13 -9 -5 -10 -55z"/>
					<path d="M6253 2748 c-1 -31 1 -47 6 -39 10 16 31 6 31 -15 0 -8 5 -14 11 -14
6 0 8 7 5 16 -3 9 -6 28 -6 42 0 21 -2 24 -10 12 -9 -13 -10 -13 -10 1 0 8 -6
23 -12 32 -11 14 -13 9 -15 -35z"/>
					<path d="M6402 2775 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
					<path d="M6448 2788 c14 -14 16 -104 3 -113 -5 -3 -12 3 -14 12 -2 10 -2 3 0
-14 2 -19 -1 -33 -7 -33 -5 0 -10 5 -10 12 0 6 -2 9 -5 6 -3 -3 0 -17 6 -32
10 -20 9 -38 0 -81 l-12 -55 -47 -2 -47 -1 50 -6 c57 -7 50 -15 79 109 8 36
19 78 25 93 21 54 11 117 -17 117 -12 0 -13 -3 -4 -12z"/>
					<path d="M6828 2793 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
					<path d="M6908 2793 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
					<path d="M6205 2780 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
					<path d="M1790 2775 c1 -6 7 -21 16 -35 l15 -25 -7 25 c-7 26 -24 52 -24 35z" />
					<path d="M4640 2759 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
					<path d="M4683 2721 c1 -33 7 -61 12 -61 12 1 8 101 -5 114 -5 5 -8 -17 -7
-53z"/>
					<path d="M5561 2760 c-2 -36 -30 -113 -44 -122 -7 -5 -3 -8 11 -8 13 0 21 4
18 8 -3 5 2 28 10 52 8 24 13 55 11 69 l-4 26 -2 -25z"/>
					<path d="M6090 2765 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
					<path d="M7220 2770 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
					<path d="M2410 2748 c0 -16 5 -28 10 -28 13 0 13 20 0 40 -8 12 -10 9 -10 -12z" />
					<path d="M3047 2759 c-9 -6 -17 -17 -17 -26 0 -15 1 -15 13 -1 6 9 19 18 27
20 8 2 10 7 4 10 -5 4 -17 2 -27 -3z"/>
					<path d="M4738 2763 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M6024 2736 c-3 -21 -4 -39 -1 -42 2 -3 8 11 11 32 4 20 5 38 2 41 -2
3 -8 -11 -12 -31z"/>
					<path d="M6192 2740 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
					<path d="M6365 2755 c-3 -8 -7 -22 -10 -31 -3 -8 0 -12 5 -9 6 3 10 14 11 23
0 14 2 13 9 -3 8 -18 9 -17 9 8 1 29 -16 37 -24 12z"/>
					<path d="M6800 2760 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z" />
					<path d="M7276 2762 c-2 -4 9 -9 25 -10 16 -1 29 2 29 8 0 12 -47 14 -54 2z" />
					<path d="M3172 2748 c-18 -18 -14 -30 8 -23 11 4 20 2 20 -4 0 -6 -6 -11 -12
-12 -7 -1 1 -5 17 -9 17 -4 38 -11 48 -15 9 -4 17 -3 17 3 0 5 -13 15 -30 22
-16 7 -32 21 -35 31 -7 22 -16 24 -33 7z"/>
					<path d="M4320 2744 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
					<path d="M6222 2730 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
					<path d="M7370 2750 c-12 -8 -9 -10 12 -10 15 0 30 5 33 10 8 13 -25 13 -45 0z" />
					<path d="M1964 2738 c-4 -6 -3 -13 4 -16 6 -2 9 -18 6 -40 l-4 -37 14 35 c9
23 10 41 4 53 -10 20 -14 21 -24 5z"/>
					<path d="M2041 2734 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M4977 2729 c-5 -20 -5 -20 14 -3 23 20 23 24 5 24 -7 0 -16 -9 -19
-21z"/>
					<path d="M5846 2718 c11 -54 25 -78 17 -33 -3 22 -6 46 -6 53 0 6 -4 12 -9 12
-6 0 -6 -14 -2 -32z"/>
					<path d="M7210 2750 c-7 -31 -7 -41 2 -46 19 -12 29 11 13 32 -8 10 -15 17
-15 14z"/>
					<path d="M3105 2730 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
					<path d="M3305 2731 c3 -5 -3 -12 -12 -14 -10 -3 -3 -5 16 -6 29 -1 32 1 21
14 -13 16 -35 21 -25 6z"/>
					<path d="M5616 2728 c-3 -7 -9 -28 -15 -47 -13 -41 -13 -41 -1 -41 6 0 10 12
10 26 0 15 4 23 10 19 6 -4 8 -15 5 -26 -4 -11 -2 -19 4 -19 6 0 11 9 11 19 0
11 3 27 6 36 4 10 2 15 -7 13 -9 -2 -13 4 -11 15 3 19 -7 23 -12 5z"/>
					<path d="M6780 2726 c-6 -9 -10 -21 -8 -28 3 -8 9 -4 17 10 15 28 9 39 -9 18z" />
					<path d="M6890 2730 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
					<path d="M7250 2730 c0 -5 14 -7 33 -4 25 5 29 3 17 -6 -12 -9 -11 -10 7 -5
18 5 21 2 16 -17 -4 -21 -4 -22 8 -4 10 16 9 22 -7 33 -21 15 -74 18 -74 3z"/>
					<path d="M3058 2723 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
					<path d="M4370 2719 c19 -7 14 -8 -23 -5 -44 3 -49 1 -58 -22 -5 -14 -7 -32
-4 -40 3 -9 1 -18 -5 -22 -11 -7 -3 -60 9 -58 3 1 13 -2 21 -5 8 -4 51 -3 95
2 l80 8 -92 2 c-50 0 -94 4 -96 9 -2 4 -1 31 2 61 l6 55 69 -3 c43 -2 66 1 62
7 -3 6 0 13 6 15 7 3 -12 5 -42 5 -44 0 -50 -2 -30 -9z"/>
					<path d="M4460 2715 c-10 -12 -10 -15 4 -15 9 0 16 7 16 15 0 8 -2 15 -4 15
-2 0 -9 -7 -16 -15z"/>
					<path d="M6836 2718 c5 -8 2 -9 -9 -5 -9 3 -19 2 -22 -3 -4 -6 28 -9 82 -8 72
1 77 2 28 5 -41 3 -55 7 -45 13 11 7 8 10 -13 10 -19 0 -26 -4 -21 -12z"/>
					<path d="M1670 2631 c0 -99 -5 -121 -27 -121 -14 0 -43 -23 -43 -34 0 -3 18
-6 40 -6 l40 0 0 125 c0 69 -2 125 -5 125 -3 0 -5 -40 -5 -89z"/>
					<path d="M1938 2691 c-5 -18 -8 -43 -8 -55 1 -19 2 -19 11 -3 6 9 9 34 7 55
l-3 37 -7 -34z"/>
					<path d="M2325 2710 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
					<path d="M2387 2708 c-2 -7 -2 -21 1 -31 4 -15 7 -16 13 -5 12 17 11 48 0 48
-5 0 -11 -6 -14 -12z"/>
					<path d="M2696 2686 c-7 -36 -24 -56 -71 -80 -25 -13 -27 -16 -9 -16 26 0 73
36 83 64 19 48 22 66 13 66 -5 0 -13 -15 -16 -34z"/>
					<path d="M3000 2709 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
					<path d="M4623 2680 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
					<path d="M5882 2700 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M2420 2700 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
					<path d="M3040 2703 c0 -5 11 -9 25 -9 14 -1 25 2 25 7 0 5 -11 9 -25 9 -14 0
-25 -3 -25 -7z"/>
					<path d="M5173 2671 c-8 -29 -7 -45 4 -65 18 -35 27 -28 13 9 -6 15 -8 43 -4
61 9 46 -1 43 -13 -5z"/>
					<path d="M5200 2696 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
					<path d="M5271 2698 c-2 -29 30 -98 45 -98 8 0 14 -7 14 -16 0 -9 5 -12 12 -8
6 4 38 6 69 5 47 -2 63 2 81 18 12 12 17 21 12 21 -6 0 -15 -5 -22 -12 -16
-16 -113 -16 -144 1 -24 12 -44 41 -59 86 -6 17 -7 17 -8 3z"/>
					<path d="M6080 2701 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
					<path d="M6380 2688 c0 -15 5 -30 10 -33 13 -8 13 25 0 45 -8 12 -10 9 -10
-12z"/>
					<path d="M6725 2700 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
					<path d="M1820 2692 c0 -5 -9 -18 -20 -30 -26 -28 -7 -29 20 -2 22 22 26 40
10 40 -5 0 -10 -4 -10 -8z"/>
					<path d="M1885 2690 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M2040 2665 c0 -22 4 -34 10 -30 6 3 10 17 10 30 0 13 -4 27 -10 30
-6 4 -10 -8 -10 -30z"/>
					<path d="M4640 2680 c0 -19 3 -21 12 -12 9 9 9 15 0 24 -9 9 -12 7 -12 -12z" />
					<path d="M4720 2689 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
					<path d="M7269 2683 c12 -10 37 -47 55 -83 19 -36 41 -65 49 -65 9 0 19 -4 23
-8 12 -12 24 -8 18 7 -3 7 -15 13 -27 12 -23 -1 -47 32 -47 63 0 14 2 14 10 1
8 -11 9 -9 6 10 -3 14 -5 28 -5 33 -1 4 -6 7 -12 7 -6 0 -9 -7 -6 -15 4 -8 2
-17 -4 -20 -5 -4 -14 7 -20 24 -6 17 -19 36 -29 41 -29 16 -34 12 -11 -7z"/>
					<path d="M2304 2673 c3 -8 6 -21 6 -28 0 -8 6 -20 13 -27 7 -7 25 -30 41 -50
16 -21 36 -38 45 -38 15 1 15 1 -1 10 -16 10 -88 102 -88 114 0 3 7 6 16 6 24
0 10 25 -15 26 -15 1 -20 -3 -17 -13z"/>
					<path d="M2772 2665 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
					<path d="M3273 2675 c-7 -8 -13 -25 -13 -39 0 -13 -7 -32 -16 -40 -14 -15 -13
-16 13 -15 15 0 22 3 15 6 -10 4 -9 10 5 25 12 15 14 22 6 25 -16 6 -5 33 13
33 8 0 14 5 14 10 0 15 -23 12 -37 -5z"/>
					<path d="M3370 2676 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"/>
					<path d="M3070 2673 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
					<path d="M4250 2659 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
					<path d="M4810 2670 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
					<path d="M6010 2660 c0 -12 -7 -20 -17 -20 -14 0 -14 -3 -5 -12 10 -10 15 -10
22 2 10 16 13 50 5 50 -3 0 -5 -9 -5 -20z"/>
					<path d="M6112 2660 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M6200 2671 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
					<path d="M6243 2660 c-3 -11 -9 -20 -14 -20 -5 0 -8 -12 -8 -27 1 -21 2 -23 6
-8 3 11 14 28 24 38 11 10 17 22 14 28 -9 14 -16 11 -22 -11z"/>
					<path d="M7222 2664 c-17 -18 -12 -36 6 -25 8 5 12 15 10 24 -3 13 -5 13 -16
1z"/>
					<path d="M4730 2660 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
					<path d="M6337 2647 c-11 -11 -17 -29 -14 -45 3 -26 4 -26 21 11 20 46 18 60
-7 34z"/>
					<path d="M6770 2655 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
					<path d="M2440 2643 c0 -11 11 -25 25 -31 14 -6 25 -10 25 -7 0 2 -11 16 -25
31 -23 24 -25 25 -25 7z"/>
					<path d="M3837 2653 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
					<path d="M5894 2649 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z" />
					<path d="M1750 2643 c0 -20 22 -73 30 -73 5 0 2 17 -7 38 -17 41 -23 50 -23
35z"/>
					<path d="M2390 2635 c7 -8 17 -15 22 -15 5 0 3 7 -6 15 -8 8 -18 15 -22 15 -3
0 -1 -7 6 -15z"/>
					<path d="M3336 2636 c21 -15 30 -14 19 3 -3 6 -14 11 -23 11 -13 0 -13 -2 4
-14z"/>
					<path d="M6683 2590 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z" />
					<path d="M1610 2613 c0 -16 5 -35 10 -43 7 -11 10 -4 10 28 0 23 -4 42 -10 42
-5 0 -10 -12 -10 -27z"/>
					<path d="M1945 2591 c-9 -28 -20 -49 -24 -47 -4 3 -7 -4 -7 -15 0 -10 3 -19 7
-19 10 0 53 120 45 127 -2 2 -12 -19 -21 -46z"/>
					<path d="M2360 2633 c0 -15 19 -37 26 -31 3 4 -1 14 -10 23 -9 9 -16 13 -16 8z" />
					<path d="M2708 2629 c-10 -5 -18 -18 -18 -27 0 -14 3 -14 18 4 9 12 24 24 32
27 13 5 13 5 0 6 -8 0 -23 -4 -32 -10z"/>
					<path d="M2962 2556 l-2 -89 38 7 c20 3 61 6 91 6 30 0 51 3 47 7 -19 19 -118
19 -143 0 -17 -13 -22 3 -26 88 -3 67 -3 66 -5 -19z"/>
					<path d="M3380 2608 c0 -18 -4 -39 -9 -47 -8 -12 -12 -11 -27 3 -9 10 -20 13
-23 8 -3 -5 -13 -12 -21 -15 -9 -3 -4 -6 13 -6 28 -1 38 -16 12 -19 -8 -1 -22
-3 -30 -4 -10 -1 -12 -5 -5 -13 5 -7 6 -15 2 -20 -20 -21 47 11 70 34 28 27
50 111 29 111 -6 0 -11 -15 -11 -32z"/>
					<path d="M4640 2592 c0 -29 4 -52 9 -52 8 0 3 90 -5 99 -2 2 -4 -19 -4 -47z" />
					<path d="M4722 2610 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
					<path d="M5871 2630 c-1 -8 6 -37 13 -65 12 -43 14 -46 15 -19 1 18 -6 47 -14
65 -8 19 -14 27 -14 19z"/>
					<path d="M6030 2630 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M7440 2608 c12 -18 28 -48 36 -67 8 -18 18 -30 22 -27 6 6 -25 74
-39 86 -3 3 -8 13 -12 23 -3 9 -11 17 -18 17 -6 0 -1 -14 11 -32z"/>
					<path d="M3080 2628 c-1 -5 -3 -21 -4 -38 -1 -29 16 -41 29 -20 3 6 2 10 -4
10 -5 0 -12 12 -14 28 -2 15 -5 24 -7 20z"/>
					<path d="M4703 2585 c0 -73 -3 -95 -10 -94 -32 5 -29 -5 2 -11 48 -9 55 -6 54
23 -1 17 -2 18 -6 5 -9 -34 -26 -19 -20 17 3 19 2 35 -3 35 -5 0 -11 15 -13
33 -3 28 -4 27 -4 -8z"/>
					<path d="M7172 2575 c-1 -37 2 -54 7 -45 5 8 5 35 1 60 l-7 45 -1 -60z" />
					<path d="M2790 2605 c0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -5 -10 -10 0 -17 36 11 54 42 10 16 13 28 7 28 -6 0 -11 -7
-11 -15z"/>
					<path d="M3830 2593 c0 -18 2 -33 4 -33 9 0 13 39 5 51 -6 10 -9 4 -9 -18z" />
					<path d="M2427 2602 c-4 -4 3 -16 14 -26 20 -17 39 -14 39 6 0 6 -3 8 -7 5 -3
-4 -14 0 -24 9 -9 8 -19 11 -22 6z"/>
					<path d="M4252 2585 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
					<path d="M7206 2573 c-12 -74 -8 -103 14 -103 17 0 20 6 18 48 l-1 47 -4 -42
c-2 -24 -9 -43 -15 -43 -7 0 -9 21 -4 65 7 71 2 88 -8 28z"/>
					<path d="M2505 2590 c3 -5 18 -10 33 -9 24 0 25 1 7 9 -28 12 -47 12 -40 0z" />
					<path d="M5615 2590 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
					<path d="M6720 2583 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z" />
					<path d="M2570 2580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M5520 2580 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
					<path d="M2615 2570 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
					<path d="M2670 2572 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z" />
					<path d="M3121 2576 c8 -8 88 -10 95 -3 4 4 -17 7 -46 7 -29 0 -51 -2 -49 -4z" />
					<path d="M4490 2535 l0 -45 -47 0 c-58 0 -48 -13 12 -16 l45 -2 0 54 c0 30 -2
54 -5 54 -3 0 -5 -20 -5 -45z"/>
					<path d="M5460 2570 c-21 -7 -22 -8 -5 -10 38 -3 69 -11 75 -18 6 -9 60 -12
60 -3 0 11 -38 31 -42 21 -2 -5 -13 -7 -25 -4 -13 4 -20 10 -17 15 6 11 -11
10 -46 -1z"/>
					<path d="M6010 2570 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M6050 2560 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
					<path d="M6232 2560 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M6814 2568 c8 -4 48 -6 89 -4 l76 4 4 -37 c4 -35 4 -35 5 7 l2 43
-95 -3 c-52 -1 -88 -6 -81 -10z"/>
					<path d="M4007 2558 c4 -7 6 -21 5 -30 -2 -10 1 -18 6 -18 5 0 12 14 14 30 4
24 1 30 -14 30 -13 0 -17 -4 -11 -12z"/>
					<path d="M1780 2550 c0 -8 5 -22 10 -30 9 -13 10 -13 10 0 0 8 -5 22 -10 30
-9 13 -10 13 -10 0z"/>
					<path d="M1835 2533 c5 -23 4 -25 -5 -13 -10 13 -11 12 -6 -3 3 -10 6 -23 6
-28 0 -5 21 -8 48 -8 l47 2 -40 6 c-41 6 -40 6 -32 55 1 5 -4 11 -11 13 -9 4
-11 -3 -7 -24z"/>
					<path d="M2040 2530 c0 -18 4 -29 10 -25 6 3 10 15 10 25 0 10 -4 22 -10 25
-6 4 -10 -7 -10 -25z"/>
					<path d="M2090 2550 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M5232 2542 c6 -7 14 -9 17 -5 4 4 10 11 14 14 5 4 -3 6 -16 5 -20 -1
-23 -4 -15 -14z"/>
					<path d="M2648 2543 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M6275 2529 c5 -20 2 -28 -17 -37 l-23 -11 23 0 c12 -1 25 -1 30 -1
11 1 4 52 -8 64 -8 8 -10 4 -5 -15z"/>
					<path d="M2700 2536 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
					<path d="M5266 2531 c-10 -16 23 -34 49 -27 27 7 34 26 9 26 -8 0 -12 -4 -9
-10 3 -5 -4 -10 -16 -10 -18 0 -19 2 -9 15 10 12 10 15 -3 15 -9 0 -18 -4 -21
-9z"/>
					<path d="M6074 2516 c-6 -27 -24 -31 -104 -27 -22 1 -23 0 -7 -10 11 -6 37 -8
67 -4 42 6 49 10 53 33 7 35 0 42 -9 8z"/>
					<path d="M3791 2507 c-6 -15 -16 -27 -22 -27 -7 0 -10 -3 -7 -6 4 -3 27 -2 52
1 35 6 46 11 46 26 0 24 -20 35 -31 18 -8 -12 -10 -12 -18 1 -8 11 -12 9 -20
-13z"/>
					<path d="M6015 2520 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
					<path d="M2090 2503 c0 -15 -2 -16 -10 -3 -6 10 -10 11 -10 3 0 -7 -7 -13 -15
-13 -8 0 -15 -4 -15 -10 0 -11 24 -13 56 -4 15 4 20 11 17 25 -7 24 -23 25
-23 2z"/>
					<path d="M2453 2499 c56 -30 124 -35 188 -14 67 22 81 34 38 32 -19 -1 -39 -9
-46 -17 -16 -20 -127 -17 -168 4 -16 9 -34 16 -40 15 -5 0 7 -9 28 -20z"/>
					<path d="M3908 2503 c-5 -20 -2 -23 22 -23 15 0 42 -3 60 -7 25 -5 32 -3 28 8
-3 9 -18 12 -50 10 -38 -2 -47 1 -50 16 -3 15 -5 15 -10 -4z"/>
					<path d="M4330 2510 c0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 -16 10 -35 10
-19 0 -35 -4 -35 -10z"/>
					<path d="M5520 2510 c-13 -9 -13 -10 0 -10 8 0 22 5 30 10 13 9 13 10 0 10 -8
0 -22 -5 -30 -10z"/>
					<path d="M6681 2513 c0 -6 -4 -18 -7 -26 -4 -11 2 -14 21 -11 33 3 46 14 17
14 -11 0 -23 8 -25 18 -3 9 -5 12 -6 5z"/>
					<path d="M6913 2513 c-26 -10 -13 -33 17 -33 28 0 30 2 20 20 -11 20 -16 22
-37 13z"/>
					<path d="M7380 2502 c0 -11 4 -23 8 -26 10 -6 9 30 -1 40 -4 4 -7 -2 -7 -14z" />
					<path d="M2137 2496 c-3 -8 2 -16 14 -19 22 -6 25 6 5 22 -10 9 -15 8 -19 -3z" />
					<path d="M4251 2494 c-18 -21 -9 -26 33 -18 l37 6 -27 14 c-31 16 -26 17 -43
-2z"/>
					<path d="M4617 2496 c-4 -10 -1 -19 9 -23 21 -8 26 1 11 22 -13 16 -14 16 -20
1z"/>
					<path d="M5900 2496 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
					<path d="M6752 2495 c-11 -13 -3 -15 63 -15 48 0 74 4 70 10 -4 6 -23 7 -46 4
-26 -4 -39 -3 -39 5 0 16 -33 13 -48 -4z"/>
					<path d="M7510 2501 c0 -5 -12 -10 -27 -10 -50 -2 -62 -4 -58 -12 3 -3 27 -6
53 -6 42 1 48 3 44 19 -5 19 -12 24 -12 9z"/>
					<path d="M1561 2492 c-1 -7 -6 -10 -13 -6 -7 4 -8 3 -4 -4 9 -15 46 -16 46 -1
0 5 -4 8 -9 5 -5 -4 -12 0 -14 6 -4 10 -6 10 -6 0z"/>
					<path d="M1800 2490 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
					<path d="M4045 2490 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
					<path d="M4170 2491 c0 -11 26 -22 34 -14 3 3 3 10 0 14 -7 12 -34 11 -34 0z" />
					<path d="M5336 2492 c-13 -22 94 -30 141 -10 23 9 25 12 9 16 -10 2 -23 -1
-29 -7 -14 -14 -90 -14 -104 0 -7 7 -13 7 -17 1z"/>
					<path d="M3210 2478 c0 -10 16 -9 50 2 22 7 20 8 -12 7 -21 -1 -38 -5 -38 -9z" />
					<path d="M6975 2480 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M3905 2156 c-75 -28 -126 -112 -109 -183 18 -75 58 -106 170 -132 80
-19 114 -44 114 -84 0 -82 -126 -103 -165 -27 -14 27 -20 30 -65 30 -27 0 -51
-4 -54 -9 -8 -13 14 -73 37 -98 50 -56 184 -75 263 -37 101 48 126 189 44 255
-21 17 -67 36 -115 49 -111 29 -148 72 -110 127 35 49 123 35 145 -23 9 -23
14 -24 61 -20 28 3 53 7 56 10 8 8 -4 48 -26 82 -37 61 -161 91 -246 60z"/>
					<path d="M4290 1968 c0 -109 5 -210 11 -233 17 -60 84 -120 151 -134 108 -23
219 22 262 104 19 36 21 60 24 248 l4 207 -56 0 -55 0 -3 -206 -3 -206 -33
-29 c-35 -32 -75 -37 -131 -18 -55 19 -61 45 -61 264 l0 195 -55 0 -55 0 0
-192z"/>
					<path d="M4880 1880 l0 -280 133 0 c157 0 192 8 237 55 32 33 35 42 35 94 0
47 -5 64 -27 94 -33 42 -34 49 -9 71 23 21 36 83 26 126 -4 17 -18 44 -32 61
-42 49 -80 59 -229 59 l-134 0 0 -280z m276 166 c23 -32 17 -72 -13 -96 -22
-17 -38 -20 -92 -18 l-66 3 -3 49 c-6 90 -4 92 83 86 61 -4 80 -9 91 -24z m1
-228 c16 -15 23 -33 23 -60 0 -55 -27 -70 -121 -66 l-74 3 -3 73 -3 72 77 0
c66 0 81 -3 101 -22z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
